import { sql } from "@vercel/postgres";
import _ from 'lodash'
import { getCountryData } from '@/components/getCountryData'
import { ProductGridTiles } from '@/components'
import { createClient } from "@/prismicio";
import Arrow from '@/assets/icons/right-arrow.svg'

interface FabricFilterGridProps {
    producttype?: any | null
    lang?: any | null
    projectname?: any | null
}

const FabricFilterGrid:React.FC<FabricFilterGridProps> = async ({producttype, lang, projectname}) => {

    const { currency, priceColumn } = getCountryData(lang);

    const client = createClient();
    const swatches = await client.getByUID("result_states", "colour-swatches");

    try {

        const {rows} = await sql`WITH product_data AS (
                                    SELECT
                                        p.productData,
                                        p.productData->>'name' AS productName,
                                        option->>'option1' AS color,
                                        p.productData->>'option2' AS option2,
                                        p.productData->>'createdAt' AS createdAt
                                    FROM
                                        public.products p
                                    LEFT JOIN
                                        public.stock s ON p.product_id = s.product_id, 
                                        jsonb_array_elements(p.productData->'productOptions') AS option
                                    WHERE p.productData->>'status' = 'Public'
                                            AND TRIM(LOWER(p.productData->>'productType')) = ${producttype.trim().toLowerCase().replace(/---/g, " & ").replace(/-/g, " ").replace('& made', '- made')}
                                            AND TRIM(LOWER(p.productData->>'projectName')) = ${projectname.trim().toLowerCase().replace(/---/g, " & ").replace(/-/g, " ").replace('& made', '- made')}
                                            AND p.productData->'images' IS NOT NULL
                                            AND p.productData->>'productSubtype' <> ''
                                            AND (
                                                LOWER(p.productData->>'orderType') = 'made to order'
                                            OR (
                                                p.productData->>'orderType' IS DISTINCT FROM 'Made To Order'
                                                AND (
                                                    (CASE
                                                        WHEN ${lang} = 'global' OR ${lang} = 'nz' THEN LOWER(s.branch_name) NOT LIKE '%au%'
                                                        ELSE LOWER(s.branch_name) LIKE '%au%'
                                                    END)
                                                    AND s.open_sales > 1
                                                )
                                            )
                                        )
                                ),
                                latest_product AS (
                                    SELECT
                                        productData,
                                        productName,
                                        color,
                                        ROW_NUMBER() OVER (PARTITION BY productName, color ORDER BY createdAt DESC) AS row_num
                                    FROM
                                        product_data
                                )
                                SELECT
                                    lp.productData,
                                    lp.productName,
                                    lp.color
                                FROM
                                    latest_product lp
                                WHERE
                                    lp.row_num = 1
                                ORDER BY
                                    LOWER(lp.productName), lp.color;
                                `;

        const {rows: rules} = await sql`SELECT rules_json
                                        FROM public.promotions
                                        WHERE status = true
                                            AND (rules_json->'rules'->>'voucher' IS NULL OR rules_json->'rules'->>'voucher' = '')
                                            AND to_timestamp(rules_json->'schedule'->>0, 'YYYY-MM-DD"T"HH24:MI:SS.MS"Z"') <= now()
                                            AND to_timestamp(rules_json->'schedule'->>1, 'YYYY-MM-DD"T"HH24:MI:SS.MS"Z"') >= now();`;

        const type = _.uniq(rows.map((product: any) => product.productdata.productSubtype).filter((colour: any) => colour != null));                                    
        const colours = _.uniq(rows.map((product: any) => product.productdata.customFields.products_1007).filter((colour: any) => colour != null));
        const fabrics = _.uniq(rows.map((product: any) => product.productdata.projectName).filter((fabric: any) => fabric != null));
        const sizes = _.uniq(
        _.flatMap(rows, (product: any) =>
            product.productdata.productOptions.map((option: any) => option.option2).filter((size: any) => size != null)
        )
        );

        const startsizes = _.uniq(
        _.flatMap(rows, (product) => 
            // Filter by product subtype before mapping
            ['Flat Sheets', 'Fitted Sheets', 'Duvet Covers'].includes(product.productdata.productSubtype)
            ? product.productdata.productOptions
                .map((option:any) => option.option2)
                .filter((size: any) => size != null)
            : []
        )
        );

        return (
            <section
                data-slice-type={"product_grid"}
                data-slice-variation="collections"
                className="p-5 lg:px-[45px]"
            >   
            <h2 className="font-sangbleu font-medium text-[24px] mt-16 text-warmcharcoal capitalize px-4 mb-4">{projectname.replace(/-/g, " ")} {producttype}</h2>
                <ProductGridTiles swatches={swatches?.data?.category_colours} filterData={{sizes: sizes, startsizes: startsizes, fabrics: fabrics, colours: colours, type: type}} products={rows} rules={rules} currency={currency} priceColumn={priceColumn} lang={lang}/>
            </section>
        )

    } catch (err) {
        console.log('Error selecting data:', err);
    }

};

export default FabricFilterGrid;
