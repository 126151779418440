'use client';
import { useState, useEffect } from 'react';
import _ from 'lodash';

interface ShippingMethodsProps {
    setShipping?: any | null;
    deliveryCity?: any | null;
    cartTotal?: any | null;
    lang?: any | null;
    place?: any | null;
    cartItems?: any | null;
}

const ShippingMethods: React.FC<ShippingMethodsProps> = ({ deliveryCity, setShipping, lang, cartTotal, place, cartItems }) => {
    const [rates, setRates] = useState([]) as any;
    const [island, setIsland] = useState(null) as any;
    const [pickup, setPickup] = useState(false) as any;
    const [storeData, setStoreData] = useState(null) as any;

    const northIslandRegions = [
        "Northland", "Auckland", "Waikato", "Bay of Plenty", "Gisborne",
        "Hawke's Bay", "Taranaki", "Manawatū-Whanganui", "Wellington"
    ];

    const nzStores = [
        "auckland", "bay of plenty", "tauranga", "papamoa", "whakatane"
    ];

    const auStores = [
        "burleigh heads"
    ];

    const storeAddresses = {
        auckland: {label: 'Pickup - Taylors Road AKL', address: 'Pickup from Taylors Road, Morningside, Auckland', branch: 4520},
        tauranga: {label: 'Pickup - Papamoa BOP',address: 'Pickup from Papamoa Plaza, 7 Gravatt Road, Tauranga', branch: 5},
        papamoa: {label: 'Pickup - Papamoa BOP',address: 'Pickup from Papamoa Plaza, 7 Gravatt Road, Tauranga', branch: 5},
        bayofplenty: {label: 'Pickup - The Strand BOP',address: 'Pickup from 83 The Strand, Whakatane, Bay of Plenty', branch: 6836},
        whakatane: {label: 'Pickup - The Strand BOP',address: 'Pickup from 83 The Strand, Whakatane, Bay of Plenty', branch: 6836},
        australia: {label: 'Pickup - Burleigh Heads',address: 'Pickup Unit 3/314 Burleigh Connection Road, Burleigh Heads, Queensland', branch: 8339}
    } as any;

   useEffect(() => {
        function getStoreAddress(inputString: any) {
            const normalizedInput = inputString.toLowerCase().trim();
        
            if (nzStores.includes(normalizedInput) && lang ==="nz") {
                setStoreData(storeAddresses[normalizedInput])
                return true;
            }
        
            if (auStores.includes(normalizedInput) && lang ==="au") {
                setStoreData(storeAddresses['australia']);
                return true;
            }
        
            setStoreData(null);
            return false;
        }

        function isNorthIsland(place: any) {
            if (!place || !place.address_components) {
                return null;
            }

            const isInNorthIsland = _.some(place.address_components, (comp) => {
                return _.includes(comp.types, "political") &&
                    !_.includes(comp.types, "route") &&
                    _.includes(northIslandRegions, comp.long_name);
            });

            return isInNorthIsland;
        }

        const getRates = async () => {
            const getRateData = await fetch(`/api/shipping?lang=${lang}`);
            const rateData = await getRateData.json();
            setRates(rateData);

            if (lang === "nz" && place && !deliveryCity) {
                const isNorth = isNorthIsland(place);
                setIsland("NZ");
            } 
            
            if((lang === "nz" || lang==="au") && !place) {
                getStoreAddress(deliveryCity);
            }

            if(lang === "global") {
                setIsland("");
            }
        };

        getRates();
    }, [lang, place, cartTotal, deliveryCity]);

    // Check if all items are digital cards
    const allDigitalCards = cartItems.every((item: any) => 
        item.productdata.productOptions[0]?.option1.toLowerCase() === "digital card"
    );

    const bulky = _.some(cartItems, (item: any) => 
        item.productdata.productType.toLowerCase() === "bedding" || item.productdata.productSubtype.toLowerCase() === "coverlets"
    );

    const giftVoucherCount = cartItems.reduce((total: number, item: any) => {
        if (item.productdata.productOptions[0]?.option1.toLowerCase() === "physical card") {
            return total + (item.quantity || 1);
        }
        return total;
    }, 0);

    useEffect(() => {
        if (allDigitalCards) {
            setShipping({ price: 0, label: "No shipping required for digital products" });
            return;
        }

        const timeoutId = setTimeout(() => {
            const giftCardPostageFee = giftVoucherCount > 0 ? 10 * giftVoucherCount : 0;

            rates.zones?.forEach((item: any) => {
                const max_order = item.max_order ?? 1000000000;
                const min_order = item.min_order ?? 0;

                if (cartTotal >= min_order && cartTotal <= max_order) {
                    const isIslandMatch = island === null || item.regions.includes(island);
                    if (isIslandMatch) {
                        let shippingPrice = item.price !== null ? item.price : 0;

                        shippingPrice += giftCardPostageFee;

                        if (bulky && item.rate_name.includes("Bulk")) {
                            setShipping({ price: shippingPrice, label: item.rate_name });
                        } else if (!bulky && !item.rate_name.includes("Bulk")) {
                            setShipping({ price: shippingPrice, label: item.rate_name });
                        }
                    }
                }
            });
        }, 300); 

        return () => clearTimeout(timeoutId);
    }, [cartTotal, island, rates, setShipping, cartItems, bulky, giftVoucherCount, allDigitalCards]);

    const CheckBox = (item: any) => (
        <label className="flex items-center mt-4">
            <span className="w-[30px] h-[30px] rounded-full inline-block cursor-pointer p-[6px] bg-white" onClick={()=>{setShipping({price: item.item.price !== null  ? item.item.price : 0, label: item.item.rate_name}); setPickup(!pickup)}}>
                <span className={(pickup == false ? "bg-warmcharcoal" : "bg-white") +" w-full h-full rounded-full block cursor-pointer"}></span>
            </span>
            <input className="hidden" type="radio" />
            <span className="inline-block ml-3 text-[12px] text-warmgrey">
                {item.item.regions + " " + item.item.rate_name}<br/> <a href="/shipping-returns" className="underline underline-offset-4">View Shipping + Returns policies</a>
            </span>
        </label>
    );

    return (
        <div>
            {storeData ? 
            <label className="flex items-center mt-4" >
                <span className="w-[30px] h-[30px] rounded-full inline-block cursor-pointer p-[6px] bg-white" onClick={()=>{setShipping({price:0, label: storeData.label, location: storeData.address, branch: storeData.branch}); setPickup(!pickup)}}>
                    <span className={(pickup === true ? "bg-warmcharcoal" : "bg-white") +" w-full h-full rounded-full block cursor-pointer"}></span>
                </span>
                <input className="hidden" type="radio"/>
                <span className="inline-block ml-3 text-[12px] text-warmgrey">{storeData.address}</span>
            </label>
            :<></>}
            {rates?.zones?.length > 0 && !allDigitalCards ? (
                rates.zones.map((item: any, index: number) => {
                    const max_order = item.max_order ?? 1000000000;
                    const min_order = item.min_order ?? 0;
                    const isBulkRate = item.rate_name.includes("Bulk");

                    if (cartTotal >= min_order && cartTotal <= max_order && (island === null || item.regions.includes(island))) {
                        if ((isBulkRate && bulky) || (!isBulkRate && !bulky)) {
                            return <CheckBox item={item} key={index} />;
                        }
                    }
                })
            ) : allDigitalCards ? (
                <p className="mt-4 text-[14px] text-warmgrey">Digital gift vouchers - no shipping - but we do need a shipping address for payment</p>
            ) : (
                <p className="mt-4 text-[14px] text-warmgrey">Calculating...</p>
            )}
        </div>
    );
};

export default ShippingMethods;
