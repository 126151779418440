'use client';

import { useEffect, useState } from 'react';
import { getCountryData } from '@/components/getCountryData';
import _ from 'lodash';

interface CartEventsProps {
    lang?: any | null;
    cartItems?: any | null;
    addToCart?: any | null;
    rules?: any | null;
    activeEvent?: any | null;
    activeCoupon?: any | null;
}

const CartEvents: React.FC<CartEventsProps> = ({ lang, cartItems, addToCart, rules, activeEvent, activeCoupon }) => {

    const { currency, priceColumn } = getCountryData(lang);
    const [loaded, setLoaded] = useState(false);
    const [cartData, setCartData] = useState(false) as any;

    const loadDataLayer = async () => {
        if (typeof window !== "undefined") {
            if (!(window as any).dataLayer) {
                (window as any).dataLayer = [];
            }
        }
    };

    const findBestRuleForProduct = (product: any, rules: any) => {
        let bestRule: any = null;
        let highestAmount = 0;

        rules.forEach((rule: any) => {
            rule.rules_json?.products.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });

        return bestRule;
    };

    const getCartData = async (cartIds: any) => {
        try {
            // Ensure dataLayer is initialized
            loadDataLayer();

            if (typeof window !== "undefined" && (window as any).dataLayer) {
                // Create a lookup object to retain quantity values
                const cartQuantityLookup = _.keyBy(cartIds, 'id');
    
                // Extract IDs
                const localCartIds = _.map(cartIds, 'id');
                cartIds = _.concat(cartIds, localCartIds);
                cartIds = _.uniq(cartIds);
    
                // Fetch cart data
                const cartRequest = await fetch(`/api/cart/local`, {
                    method: 'POST',
                    body: JSON.stringify({ ids: localCartIds, lang: lang }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                const cartResult = await cartRequest.json();

                const finalCartResult = Array.isArray(cartResult?.rows)
                    ? cartResult.rows?.map((item: any) => ({
                        ...item,
                        quantity: cartQuantityLookup[item?.productdata?.productOptions[0].id]?.quantity || 1 // Default quantity to 1 if not found
                    }))
                    : [] as any; // Handle the case where cartResult is not an array

                const eventLineItems =[] as any;
            
                // console.log('xx:', finalCartResult)

                let cartValue = 0;

                finalCartResult?.map((item: any, index: number) => {

                    item.productdata?.productOptions?.map((option: any) => {
        
                        const applicablePromotion = findBestRuleForProduct(option, rules);
        
                        let discountPrice = 0;
                        let discounted = 0;
                
                        if (applicablePromotion) {
                            discounted = applicablePromotion?.rules_json?.rules.amount;
                            discounted = applicablePromotion?.rules_json?.rules.type === "percentage" ? discounted / 100 : discounted;
                            discountPrice = Number((option.priceColumns[priceColumn] - (option.priceColumns[priceColumn] * discounted)).toFixed(2));
                        }

                        cartValue += (discountPrice ? discountPrice : option.priceColumns[priceColumn])*item.quantity
            
                        const lineItem = {
                                item_id: option.id,
                                index: index,
                                item_name: item?.productdata?.name,
                                item_category: "Home & Garden > Linens & Bedding",
                                item_variant: `${option.option1} - ${option.option2}`,
                                price: discountPrice > 0 ? Number(discountPrice.toFixed(2)) : Number(option.priceColumns[priceColumn]?.toFixed(2)),
                                quantity: item.quantity,
                                discount: discounted ? discounted : null
                        };
            
                            eventLineItems.push(lineItem);
                            
                        });
                    });

                (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

                try {
                    (window as any).dataLayer.push({
                        event: activeEvent,
                        ecommerce: {
                            currency: currency.replace("$", ""),
                            value: cartValue,
                            coupon: activeCoupon,
                            items: eventLineItems
                        }
                    });
                } catch (error: any) {
                    // console.log(error);
                }
                setLoaded(true);
            } else {
                // console.log("dataLayer is not defined");
            }

        } catch (error) {
            // console.log("Error fetching cart data:", error);
            // Optionally handle the error, like setting an empty cart or showing an error messagesetCartData([]);
        }
    };

    useEffect(() => {
        loadDataLayer();

        if (!cartItems) return;  // Exit early if product or rules are not defined

        if (typeof window !== "undefined" && (window as any).dataLayer && addToCart === false) {
            getCartData(cartItems);
        } else {
            // console.log("dataLayer is not defined");
        }

    }, [lang, addToCart, activeEvent]);  // Add dependencies if these props may change

    return null;  // return null instead of an empty fragment if no JSX is needed
}

export default CartEvents;
